<template>
    <Dialog header="Submission Error" v-model:visible="display" :modal="true" :closable="false" class='submission-error-modal'>
        <span class='body-text'>{{errorMessage}}</span>
        <template #footer>
            <div class='input-container'>
                <span :class="{'p-float-label': true, 'input-error': v$.inputValue.$error }">
                    <InputText id='username' type="text" v-model="inputValue" class='username-input' @input='resetError'/>
                    <label for="username" class='input-label'>Enter Username</label>
                </span>
                <template v-for="error of v$.inputValue.$errors" :key="error.$uid">
                    <span class='error-message' v-if="!error.$pending">{{error.$message}}</span>
                </template>
            </div>
            <div class='button-container'>
                <Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='close'/>
                <Button label="Submit" class='accept p-button-raised p-button-rounded' @click='submit'/>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';

import useVuelidate from '@vuelidate/core'
import { helpers, maxLength, minLength, required} from '@vuelidate/validators';

import CustomValidationUtils from '../../utilities/CustomValidationUtils';

export default {
    name: 'SignupSubmissionErrorModal',

    emits: ['resubmit-username'],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            inputValue: '',
            errorMessage: null,
            display: false
        }
    },

    components: {
        Dialog, InputText
    },

	methods: {
        open(message) {
            this.display = true;
            this.errorMessage = message;
        },
		close() {
			this.display = false;
            this.inputValue = null;
		},
        submit() {
            this.v$.inputValue.$touch();

            if (this.v$.inputValue.$errors.length == 0) {
                this.$emit("resubmit-username", this.inputValue);
                this.close();
            }
        },

        resetError() {
            this.v$.inputValue.$reset();
        }
	},

    validations() {
        return {
            inputValue: {
                    required: helpers.withMessage('Field is required.', required),
                    maxLength: helpers.withMessage('Must be 20 characters or less.', maxLength(20)),
                    minLength: helpers.withMessage('Must be at least 3 characters long.', minLength(3)),
                    noSpecialCharacters: helpers.withMessage('Should not contain any special characters.', CustomValidationUtils.noSpecialCharacters),
                    noSpaces: helpers.withMessage('Should not contain any spaces, only underscores.', CustomValidationUtils.noSpaces),
                    usernameTaken: helpers.withMessage('Username is not available.', helpers.withAsync(CustomValidationUtils.usernameTaken))
            }
        }
    }
}
</script>

<style>
.p-dialog-mask.p-component-overlay {
	background-color: rgba(50,54,78, 0.7);
}

.submission-error-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 360px;
	box-shadow: none;
}

.submission-error-modal .p-dialog-header {
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    color: #32364E;
}

.submission-error-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

.submission-error-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 10px 25px;
}
.submission-error-modal .body-text {
    font-size: 16px;
    color: #a6a6a6;
}

.submission-error-modal .p-dialog-footer {
	padding-top: 1.5rem;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}

.submission-error-modal .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
</style>
<style scoped>

* {
    font-family: "Trebuchet MS", "Verdana";
}

/* Shaking animation */
@keyframes shakeError {
  0% {
    transform: translateX(0); }
  15% {
    transform: translateX(0.375rem); }
  30% {
    transform: translateX(-0.375rem); }
  45% {
    transform: translateX(0.375rem); }
  60% {
    transform: translateX(-0.375rem); }
  75% {
    transform: translateX(0.375rem); }
  90% {
    transform: translateX(-0.375rem); }
  100% {
    transform: translateX(0); } }

.submission-error-modal .input-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.submission-error-modal .username-input {
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
}
.submission-error-modal .username-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}

.p-float-label {
    margin-top: 15px;
}
.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}

.input-error .username-input:hover,
::v-deep(.input-error .username-input:hover),
.input-error .username-input:enabled:focus,
::v-deep(.input-error .username-input:enabled:focus),
.input-error .username-input,
::v-deep(.input-error .username-input) {
    border-color: #E63E3E;
}
.input-error .input-label {
    color: #E63E3E;
}
.error-message:nth-child(2) {
    padding-top: 20px;
}
.error-message {
    color: #E63E3E;
    padding-left: 10px;
    text-align: left;
}

.submission-error-modal .p-dialog-footer .p-button {
    font-size: 16px;
	background: #FFFFFF;
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

 .submission-error-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 10px;
 }

 .submission-error-modal .p-dialog-footer .accept {
    border-color: #33CC99;
    padding: 10px 30px;
 }

.submission-error-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #33CC99;
 }
  .submission-error-modal .p-dialog-footer .accept:focus {
     box-shadow: none;
     border-color: #33CC99;
 }

.submission-error-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
 } 
 .submission-error-modal .p-dialog-footer .cancel:focus {
     box-shadow: none;
     border-color: #32364E;
 }
</style>